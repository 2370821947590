import { Pagination, Navigation } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const smOptions: CarouselProps = {
  slidesPerView: "auto",
  pagination: {
    enabled: false
  },
  modules: [Pagination],
  theme: "pagination-tiffany-tiffany30"
}

//Old options for promotions carusels
export const mdOptionsOld: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 4,
  pagination: {
    type: "progressbar",
    enabled: true
  },
  grabCursor: true,
  navigation: true,
  modules: [Navigation, Pagination],
  theme: "navigation-dark-rails-promo"
}

export const mdOptions: CarouselProps = {
  slidesPerView: "auto",
  slidesPerGroup: 4,
  grabCursor: true,
  navigation: true,
  modules: [Navigation],
  theme: "navigation-dark-middle"
}
